.homeContainer {
    min-height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.overlay{
    position: absolute;
    top: 20px;
    left: 20px;
    width: 120px;
    z-index: -1;
}
.homeContainer .top{
    width: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.homeContainer .top .left{
    max-width: 45rem;
    margin-right: 8rem;
}
.homeContainer .top .left h5{
    font-weight: normal;
    font-size: 23px;
}
.homeContainer .top .left h1{
    line-height: 150%;
    letter-spacing: 1%;
    font-weight: normal;
    font-size: 42px;
}
.homeContainer .top .left span{
    position: relative;
}
.homeContainer .top .left span::after{
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20px;
    background-color: #E92577;
    z-index: -1;
}

.homeContainer .top .right .imagebox{
    background-color: #E92577;
    width: 280px;
    height: 280px;
    position: relative;
    border-radius: 5px;
}
.homeContainer .top .right .imagebox img{
    margin: 5px 0 -5px 5px;
    width: 100%;
    border-radius: 5px;
} 
.homeContainer .bottom{
    margin-top: 30px;
    max-width: 80%;
    font-size: 18px;
    text-align: center;
    word-wrap: break-word;
}
.pink-bold{
    color: #E92577;
    font-weight: 700;
    text-decoration: none;
}

@media screen and (max-width: 1190px) {
    .overlay{
        width: 100px;
    }
    .homeContainer .top .left{
        max-width: 37rem;
        margin-right: 6rem;
    }
    .homeContainer .top .left h5{
        font-size: 21px;
    }
    .homeContainer .top .left h1{
        line-height: 140%;
        font-size: 40px;
    }
    .homeContainer .top .right .imagebox{
        width: 260px;
        height: 260px;
        border-radius: 4px;
    }
    .homeContainer .bottom{
        font-size: 17px;
    }
}

@media screen and (max-width:1024px) {
    .homeContainer .top .left{
        max-width: 28rem;
        margin-right: 4rem;
    }
    .homeContainer .top .left h5{
        font-size: 21px;
    }
    .homeContainer .top .left h1{
        line-height: 130%;
        font-size: 38px;
    }
    .homeContainer .top .right .imagebox{
        width: 240px;
        height: 240px;
    }
    .homeContainer .bottom{
        font-size: 16px;
    }
}

@media screen and (max-width:769px) {
    
    .homeContainer .top .left{
        max-width: 25rem;
        margin-right: 3.5rem;
    }
    .homeContainer .top .left h5{
        font-size: 19px;
    }
    .homeContainer .top .left h1{
        line-height: 120%;
        font-size: 35px;
    }
    .homeContainer .top .left span::after{
        height: 15px;
    }
    .homeContainer .top .right .imagebox{
        width: 220px;
        height: 220px;
    }
    .homeContainer .bottom{
        font-size: 15px;
    }
}

@media screen and (max-width:700px) {
    .overlay{
        top: 40px;
        left: unset;
        right: 20px;
        transform: scale(1.3);
    }
    .homeContainer .top{
        flex-direction: column;
    }
    .homeContainer .top .left{
        max-width: 80%;
    }
    .homeContainer .top .left h5{
        font-size: 18px;
        margin: 10px 0;
    }
    .homeContainer .top .left h1{
        line-height: 110%;
        font-size: 33px;
    }
    .homeContainer .top .right {
        display: flex;
        align-items: center;
        justify-content: left;
        margin: 40px 0;
    } 
    .homeContainer .top .right .imagebox{
        width: 200px;
        height: 200px;
    }
    .homeContainer .bottom{
        font-size: 16px;
    }
}

@media screen and (max-width: 447px) {
    .overlay{
        width: 70px;
    }
    .homeContainer .top .left{
        max-width: 65%;
    }
    .homeContainer .top .left h5{
        font-size: 17px;
    }
    .homeContainer .top .left h1{
        font-size: 32px;
    }
    .homeContainer .top .left span::after{
        height: 13px;
    }
    .homeContainer .top .right .imagebox{
        width: 180px;
        height: 180px;
    }
    .homeContainer .bottom{
        font-size: 14px;
    }
}