.contactContainer {
  padding: 1rem 12rem;
}

.contactContainer h1 {
  width: 540px;
  font-size: 45px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #0a0a14;
  margin: 1rem 0;
}

@media screen and (max-width: 1024px) {
  .contactContainer {
    padding: 1rem 2rem;
  }
}

@media screen and (max-width: 447px) {
  .contactContainer h1 {
    width: 100%;
    font-size: 34px;
    line-height: 130%;
  }
}

.contact-area {
  display: flex;
  height: 70vh;
  width: 100%;
  align-items: center;
  justify-content: start;
}
.contact-page {
  height: 100%;
  max-height: 420px;
  width: 90%;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.208),
    -5px -5px 10px rgba(0, 0, 0, 0.208);
  display: flex;
  background: #e92577;
  background: linear-gradient(135deg, #b21aa1, #e92577 50%, #e92577 100%);
  background: -moz-linear-gradient(135deg, #b21aa1, #e92577 50%, #e92577 100%);
  background: -webkit-linear-gradient(135deg, #b21aa1, #e92577 50%, #e92577 100%);
}
.about {
  flex-basis: 40%;
  padding: 20px 30px;
}
form {
  flex-basis: 60%;
  padding: 20px 30px;
}
.about {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
}
.row {
  margin: 0 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 30px;
}
.social {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100px;
}
.social ul {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.social ul li {
  list-style: none;
  margin-right: 15px;
  font-size: 25px;
  height: 40px;
  width: 40px;
  transition: 0.3s;
  display: grid;
  place-content: center;
  border-radius: 50%;
}
.social ul li a {
  color: #fff;
}
.social ul li:hover {
  background: #fff;
}
.social ul li:hover a {
  color: #a81b56;
}
.row h4 {
  font-size: 42px;
  font-weight: 800;
  color: #fff;
  margin-top: 0;
}
.row span {
  font-size: 18px;
  color: #efefef;
  font-weight: 500;
}
.row .form-icon {
  margin-right: 5px;
}
form {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
}
form .row-form {
  width: 100%;
  margin: 10px;
}
.row-form h4 {
  font-size: 42px;
  font-weight: 800;
  color: #e92577;
  margin-bottom: 20px;
}
.row-form label {
  min-width: 50px;
}
.row-form input {
  width: 70%;
  height: 25px;
}
.flex-30 {
  display: flex;
}
label {
  flex-basis: 25%;
  font-size: 18px;
  font-weight: 500;
}
input {
  flex-basis: 70%;
  outline: none;
  padding: 0 5px;
  letter-spacing: 1px;
  color: #303030;
  font-size: 16px;
}
form textarea {
  flex-basis: 70%;
  resize: none;
  outline: none;
  padding: 0 5px;
  letter-spacing: 1px;
  color: #303030;
  font-size: 16px;
}
.submit-btn {
  color: #fff;
  background: #e92577;
  padding: 8px 30px;
  border-radius: 0.2em;
  outline: none;
  border: none;
  transition: 0.3s;
}
.submit-btn:hover {
  background: #a81b56;
}
/* Only form */
@media screen and (max-width: 983px) {
  .contact-area {
    display: flex;
    height: 140vh;
  }
  .contact-page {
    max-height: 650px;
    width: 100%;
    flex-direction: column-reverse;
  }
  .about {
    flex-basis: 100%;
    max-height: 250px;
  }
  form {
    flex-basis: 100%;
  }
  .row {
    padding: 10px 5px;
  }
  .row h4 {
    font-size: 36px;
    font-weight: 600;
  }
  .row span {
    font-size: 16px;
  }
  .row-form label {
    min-width: 100%;
    font-size: 16px;
  }
  .row-form input {
    width: 100%;
    height: 25px;
  }
  form .row-form {
    width: 100%;
    margin: 10px;
    display: flex;
    flex-direction: column;
  }
  .submit-btn {
    margin-top: 10px;
  }
  form textarea {
    width: 100%;
  }
  .row-form h4 {
    font-size: px;
  }
  .social{
      margin: 0;
  }
  .social ul li {
    list-style: none;
    margin-right: 7px;
    font-size: 20px;
    height: 30px;
    width: 30px;
  }
}
