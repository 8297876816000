.FooterContainer{
    padding: 3rem 12rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    font-size: 17px;
}

.FooterContainer ul{
    list-style: none;
    display: flex;
}

.FooterContainer ul li{
    margin: 5px 5px;
}

.credit{
    color: #000;
    text-decoration: none;
}
.FooterContainer ul li a{
    text-transform: capitalize;
    text-decoration: none;
    padding: 5px 0px;
}

@media screen and (max-width: 1024px) {
    .FooterContainer{
        padding: 2rem 2rem;
    }
}
