.skills{
    max-width: 100%;
    min-height: 70vh;
    padding: 1rem 12rem;
}
.heading{
    margin-top: 20px;
    font-weight: 700;
    font-size: 28px;
    line-height: 150%;
    letter-spacing: .1em;
    text-transform: uppercase;
}

.skills .content {
    margin-top: 40px;
    display: flex;
}
.skills .content .left{
    flex-basis: 60%;
}
.skills .content .right{
    flex-basis: 40%;
    display: flex;

}
.skills .content .right ul{
    list-style: none;
    font-family: 'Lora', serif;
}

.skills .content .right li{
    font-size: 20px;
    font-family: 'Lora', serif;
    font-weight: 500;
    padding: 10px;
    display: flex;
}
.skills .content .right ul .preview{
    width: 30px;
    height: 20px;
    margin-right: 8px;
}

@media screen and (max-width: 1190px) {
    .skills{
        padding: 1rem 6rem;
    }
    .heading{
        font-size: 26px;
    }
    .skills .content .right li{
        font-size: 19px;
    }
}

@media screen and (max-width:1024px) {
    .skills{
        padding: 1rem 2rem;
    }
    .heading{
        font-size: 24px;
    }
    .skills .content .right li{
        font-size: 18px;
    }
}

@media screen and (max-width:769px) {
    .heading{
        font-size: 20px;
    }
    .skills .content{
        display: block;
    }
    .skills .content .right{
        margin-top: 50px;
    }
    .skills .content .right li{
        font-size: 18px;
        padding: 5px;
    }
    .skills .content .right ul .preview{
        width: 20px;
        height: 14px;
    }
}

@media screen and (max-width: 447px) {
    .skills .content .right{
        margin-top: 30px;
    }
    .skills .content .right li{
        font-size: 16px;
    }
}